export const environment = {
   production: true,
   API_KEY_IMAGE4IO: "undefined",
   API_SECRET_IMAGE4IO: "undefined",
   API_URL_IMAGE4IO: "undefined",
   APP_ID_REALM: "schlosswochen-inscription-fggvp",
   NODE_VERSION: "v16.19.1",
   APP_ID_REALM_READWRITE: "data-pcuoo",
   MAX_NUMBER_OF_RESERVATIONS: "3",
   MAX_NUMBER_OF_WEEKS: "3",
   UPCOMING_YEAR: "2023",
   CONTEXT: "branch-deploy"
};